import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Seo from "../components/seo"
//import Category from "~/components/pages/blog/categories"
//import Tag from "~/components/pages/blog/tags"
import * as containerStyles from '~/components/pages/blog/blog.module.scss'
import Arrow from "~/images/common_icon_arrow_r.svg";
import PaginateArrowL from "~/images/paginate_icon_arrow_l.svg";
import PaginateArrowR from "~/images/paginate_icon_arrow_r.svg";
import Pagination from "../components/modules/pagination"

const BlogIndex = ({ data, pageContext }) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <>
        <Seo title="Blog" pageUrl="blog" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </>
    )
  }

  /* 後ほど復活予定、カテゴリへのリスト
  <dl className="contentComponent categoryComponent">
    <dt className="heading">CATEGORY</dt>
    <Category />
  </dl>
  <div className={containerStyles.currentCategory}>
    <h2 className="heading contentComponent">すべての読みもの</h2>
  </div>

  タグ一覧
  <dl className="contentComponent tagListComponent mincho">
    <dt className="heading">TAG</dt>
    <Tag />
  </dl>
  */

  return (
    <>
      <Seo title="Blog" pageUrl="blog" />

      <div className="blog">
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li><Link to="/" itemProp="url">TOP</Link> <span><Arrow /></span></li>
            <li>BLOG</li>
          </ul>
        </nav>
        <section>
          <header className="borderLine titleComponent">
            <h1 className="contentComponent heading with-english"><span>ブログ</span>Blog</h1>
            <p className="contentComponent heading">靴への想い、長持ちさせる方法、日々の出来事などをお伝えしていきます。</p>
          </header>
          <div className={containerStyles.articleList}>
            {posts.map(post => {
              const title = post.title
              const featuredImage = {
                image: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
                alt: post.featuredImage?.node?.alt || ``,
              }
              const category = {
                name: post.categories?.nodes[0]?.name,
                uri: post.categories?.nodes[0]?.uri,
              }
              const tags = post.tags?.nodes
              const tagList = tags.map(tag => {
                return(
                  <li key={tag.uri}>#{tag.name}</li>
                )
              })

              return (
                <article
                  className={containerStyles.articleItem}
                  itemScope
                  itemType="http://schema.org/Article"
                  key={post.id}
                >
                  <Link to={`/blog/${post.id}`} itemProp="url" className="contentComponent">
                    <div className={containerStyles.thmbnail}>
                      {featuredImage?.image ? (
                        <GatsbyImage
                          image={featuredImage.image}
                          alt={featuredImage.alt} />
                      ) : <StaticImage
                        src="../images/top_mv_pc.jpg"
                        alt=""
                        placeholder="blurred"
                        layout="fullWidth" />}
                    </div>
                    <section className={containerStyles.articleDetail}>
                      <header className="mincho">
                        {category?.name && (
                          <span className={containerStyles.articleCategory}>
                            {category.name}
                          </span>
                        )}
                        <h2 className={containerStyles.articleTitle + " heading is-secondary"}>
                          <span itemProp="headline">{parse(title)}</span>
                        </h2>
                      </header>
                      <small className={containerStyles.articleDate + " mincho"}>{post.date}</small>
                      <div className={containerStyles.articleExpert} itemProp="description" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                      <ul className={containerStyles.articleTags + " mincho"}>
                      {tagList}
                      </ul>

                    </section>
                  </Link>
                </article>
              )
            })}
          </div>

        </section>

        <Pagination pageContext={pageContext} />
      </div>

    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        id
        date(formatString: "YYYY.MM.DD")
        title
        excerpt
        tags {
          nodes {
            uri
            name
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
